<template>
  <section id="dashboard-analytics">
    <div class="d-flex justify-content-between align-items-center">
      <h2>{{ $t('campaignhometitle') }}</h2>
      <div>
          <router-link
            class="botonResponsive"
            to="/campaignWhite"
            v-if="groupRol == 'whitelabel'"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              {{ $t('newcampaignbutton') }}
            </b-button>
          </router-link>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-right
            variant="primary"
            ref="toggleSide"
            v-else
          >
            {{ $t('newcampaignbutton')}} 
          </b-button>
        </div>
      </div>
      <hr class="mb-3">
      <b-sidebar
        :key="change"
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadow
      >
        <sidebar-content :key="change" @campaignCreated="appendCampaign"/>
      </b-sidebar>
    <!-- BOTON CREAR CAMPAÑAS -->
    <!-- CARD CAMPAÑAS -->
    <b-row class="match-height">
      <b-col>
        <b-tabs>
          <b-tab
            active
            :title="$t('campaignactive')"> 
             <div v-if="!loading && numberActiveCampaign == 0">
              <img class="CampaignImage" src="@/assets/images/elements/campaign/campanas.png">
             </div> 
            <b-row
              class="mt-3"
              :key="change_campaigns"
              v-if="active_campaigns.length > 0"
            >
              <b-col
                v-for="(campaignInfo, index) in active_campaigns"
                v-bind:key="index"
                lg="4"
                style="position:relative"
              >
                <CampaignCard :campaignData="campaignInfo" v-on:has_been_archived="onArchived" @deletedCampaign="deletedCampaign"></CampaignCard>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            :title="$t('campaigninactive')"
          >
            <div v-if="!loading && numberArchiveCampaign == 0">
              <img class="CampaignImage" src="@/assets/images/elements/campaign/campanas.png">
             </div> 
            <b-row
              class="mt-3" 
              :key="change_campaigns"
              v-if="archive_campaigns.length > 0"
            >
              <b-col
                v-for="(campaignInfo, index) in archive_campaigns"
                v-bind:key="index"
                lg="4"
              >
                <CampaignCard :campaignData="campaignInfo" v-on:has_been_desarchived="onDesarchived" :campaignArchived="true" @deletedCampaign="deletedCampaign"></CampaignCard>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BRow, BCol, BTabs, BTab, BButton, BSidebar, VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SidebarContent from '@/views/dashboard/newCampaign.vue'
import service from '@/services/dashboard'
import {directive} from 'vue-awesome-swiper'
import utils from '@/libs/utils'
import CampaignCard from "@/views/components/campaign/campaignCard"
import service_profile from '@/services/others'
export default {
  name: 'CampaignV2',
  components: {
    CampaignCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    BSidebar,
    SidebarContent,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    swiper: directive
  },
  data () {
    return {
      data: {},
      limit: 10,
      campaigns: [],
      banners: [],
      utils,
      hRol: '',
      width: window.screen.width,
      change: false,
      numberActiveCampaign:  0,
      numberArchiveCampaign: 0,
      groupRol: 'brand',
      active_campaigns: [],
      archive_campaigns: [],
      change_campaigns: false,
      loading: false
    }
  },
  mounted () {
    this.getCampaigns()
    const data_user = JSON.parse(localStorage.getItem('userData'));

    this.groupRol = data_user.main_group.name;

    service_profile.getMaxViews()
      .then(response => {
        this.$root.$emit('change_max_views', response.max_views)
      })
  },
  methods: {
    changeCampaignsLength() {
      this.numberActiveCampaign = this.active_campaigns.length;
      this.numberArchiveCampaign = this.archive_campaigns.length;
      this.change_campaigns = !this.change_campaigns;
    },
    onArchived(data) {
      this.active_campaigns.forEach((campaign, index) => {
        if (campaign.uuid === data.uuid) {
          campaign.is_archived = true;
          this.active_campaigns.splice(index, 1);
          this.archive_campaigns.push(data)
        }
      })

      this.changeCampaignsLength();
    },
    onDesarchived(data) {
      this.archive_campaigns.forEach((campaign, index) => {
        if (campaign.uuid === data.uuid) {
          campaign.is_archived = false;
          this.archive_campaigns.splice(index, 1);
          this.active_campaigns.push(data)
        }
      })

      this.changeCampaignsLength();
    },
    deletedCampaign (is_archived, uuid) {
      if (is_archived) {
        this.archive_campaigns.forEach((campaign, index) => {
          if (campaign.uuid === uuid) {
            this.archive_campaigns.splice(index, 1);
          }
        })
      } else {
        this.active_campaigns.forEach((campaign, index) => {
          if (campaign.uuid === uuid) {
            this.active_campaigns.splice(index, 1);
          }
        })
      }
      this.changeCampaignsLength()
    },
    activeCampaigns() {
      const filtered = this.campaigns.filter(campaign => campaign.active && !campaign.is_archived);

      return filtered
    },
    archiveCampaigns() {
      const filtered = this.campaigns.filter(campaign => campaign.active && campaign.is_archived);
      return filtered
    },
    getCampaigns () {
      this.loading = true;
      this.$vs.loading()
      service.getCampaign()
        .then(response => {
          this.campaigns = response.response.results
          this.active_campaigns = this.activeCampaigns();
          this.archive_campaigns = this.archiveCampaigns()
          this.changeCampaignsLength()
          this.loading = false;
          this.$vs.loading.close()
        })
    },
    appendCampaign(campaign) {
      this.$refs.toggleSide.click()
      this.campaigns.push(campaign)
      this.active_campaigns.push(campaign)
      this.changeCampaignsLength()
      this.change = !this.change;
    }
  },

  watch: {
    '$i18n.locale'() {
      this.change = !this.change;
    }
  }
}
</script>

<style>
.b-sidebar{
  width: 600px;
}
.card-congratulation-medal {
  height: 100%;
  max-height: 100%;
}
.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner-leads .card-body {
  display: flex;
  justify-content: flex-start;
}
.icon-leads {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367F0;
  padding: 5px;
  margin-right: 10px;
  border-radius: 10px;
}
.objetfit img{
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.header-card-creator {
  height: 188px;
}
.text-black {
  color: #6E6B7B;
}
.link-disabled {
  cursor: default !important;
}
.CampaignImage {
  width:80%; 
  margin-left: 10%;
}
</style>
